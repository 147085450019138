import useNotifications from '@/composables/useNotifications'
import { getComplaint, updateComplaint } from '@/@core/queries/complaints'
import { updateActionPlan } from '@/@core/queries/action-plans'
import { useRouter } from '@core/utils/utils'
import { ref, computed, set } from '@vue/composition-api'
import i18n from '@/libs/i18n'
import axios from '@axios'
import useCommon from "@/views/organization/useCommon"
import store from '@/store'
import realmConnection from '@/views/habit/realm'
import useCommonTodo from '@/views/apps/todo/useCommonTodo'

export default function useComplaintEdit() {
  const { showSuccessMessage, showErrorMessage } = useNotifications()
  const { route, router } = useRouter()
  const { formatDate } = useCommon()
  const { updateItem, ObjectId } = realmConnection()
  const { sendEmailCase } = useCommonTodo()
  const complaintData = ref({})
  const isSubmitting = ref(false)
  const updateActiveTab = ref(false)
  const userData = store.state?.userStore?.userData
  const collection = 'complaint'
  const query = { _id: ObjectId(route.value.params.id) }

  const show = () => {
    isSubmitting.value = true
    axios
      .post('/graphql', {
        query: getComplaint,
        variables: { query: { _id: route.value.params.id } },
      })
      .then(({ data }) => {
        if (data.errors) throw new Error(data.errors[0].message)

        const c = data?.data?.complaint || {}

        // Sort action plans by origin index
        c.actionPlans?.sort((a, b) => a.originIndex - b.originIndex)

        complaintData.value = {
          ...c,
          themesComputed: computed(() => c.themes?.map(theme => theme.isNewOption ? theme.value : i18n.t(`complaintThemes.${theme.value}`)).join(" / ")),
          companyRelationshipComputed: c.companyRelationship ? computed(() => c.companyRelationship.isNewOption ? c.companyRelationship.value : i18n.t(`companyRelationships.${c.companyRelationship.value}`)) : '',
          reportedPerson: c.reportedPerson?._id,
          reportedPersonResponsible: c.reportedPersonResponsible?._id,
          areas: c.areas?.map(({ _id }) => _id),
          associatedCases: c.associatedCases?.map(({ _id }) => _id),
          proposalResponsible: c.proposalResponsible?._id,
          approvalResponsible: c.approvalResponsible?._id,
          verificationResponsible: c.verificationResponsible?._id,
          actionPlans: c.actionPlans?.map(e => ({ ...e, executionResponsible: e.executionResponsible?._id })) || [],
          state: c.stage === 'end' ? 'completed' : 'upToDate'
        }

        // Set reactive properties
        complaintData.value.actionPlans.forEach(e => {
          set(e, 'approvalNextStage', e.stage === 'analysis' || e.stage === 'corrective' || e.stage === 'verification' ? e.stage : '')
          set(e, 'correctiveNextStage', e.stage === 'analysis' || e.stage === 'verification' ? e.stage : '')
          set(e, 'verificationNextStage', e.stage === 'analysis' || e.stage === 'corrective' || e.stage === 'end' ? e.stage : '')
          set(e, 'implementationNextStage', e.stage === 'correctiveMeasures' || e.stage === 'finalized' ? e.stage : '')
          set(e, 'finalizedNextStage', e.stage === 'correctiveMeasures' || e.stage === 'implementation' || e.stage === 'end' ? e.stage : '')
          set(e, 'evidenceFile', [])
        })

        // Check each action plan end date to see if the complaint is delayed
        if (complaintData.value.state === 'upToDate') {
          for (const actionPlan of complaintData.value.actionPlans) {
            if (actionPlan.endDate && new Date(actionPlan.endDate) < new Date()) {
              complaintData.value.state = 'delayed'
              break
            }
          }
        }

        updateActiveTab.value = true
      })
      .catch(error => {
        console.log(error)
        showErrorMessage(i18n.t('message.complaint_fetch_error'))
      })
      .finally(() => {
        isSubmitting.value = false
      })
  }

  const update = (complaintData) => {
    if (complaintData.actionPlansToDelete?.length) deleteActionPlans(complaintData.actionPlansToDelete)

    const payload = {
      associatedCases: complaintData.associatedCases ? { link: complaintData.associatedCases } : null,
      proposalResponsible: complaintData.proposalResponsible ? { link: complaintData.proposalResponsible } : null,
      approvalResponsible: complaintData.approvalResponsible ? { link: complaintData.approvalResponsible } : null,
      verificationResponsible: complaintData.verificationResponsible ? { link: complaintData.verificationResponsible } : null,
      approvalDueDate: complaintData.approvalDueDate ? formatDate(complaintData.approvalDueDate) : null,
      verificationDueDate: complaintData.verificationDueDate ? formatDate(complaintData.verificationDueDate) : null,
      approvalComment: complaintData.approvalComment || '',
      verificationComment: complaintData.verificationComment || '',
      problemStatement: complaintData.problemStatement || '',
      why1: complaintData.why1 || '',
      why2: complaintData.why2 || '',
      why3: complaintData.why3 || '',
      why4: complaintData.why4 || '',
      why5: complaintData.why5 || '',
      actionPlans: { link: complaintData.actionPlansIds || [] },
      isCompletedOverdue : complaintData.isCompletedOverdue || null,
      lastModifiedBy: userData?.worker_id?.$oid ? { link: userData.worker_id.$oid } : null,
      reportConclusion: complaintData.reportConclusion || '',
      why6: complaintData.why6 || '',
      why7: complaintData.why7 || '',
      why8: complaintData.why8 || '',
      why9: complaintData.why9 || '',
      why10: complaintData.why10 || '',
      finalizedComment: complaintData.finalizedComment || '',
      rootCause: complaintData.rootCause || 100,
      rootCauseCorrectiveMeasures: complaintData.rootCauseCorrectiveMeasures || 100,
    }

    // If complaint is in 'registration' stage then set stage as 'analysis'
    // If complaint is in 'analysis' stage and there are action plans then set stage as 'approval'
    // If complaint is in 'approval' stage and all action plans are in stage 'corrective/verification' then set stage as 'corrective' if there is at least one action plan in term, otherwise set stage as 'verification'
    // If complaint is in 'corrective' stage and all action plans are in stage 'verification' then set stage as 'verification'
    // If complaint is in 'verification' stage and all action plans are in stage 'end' then set stage as 'correctiveMeasures' for Karin Law and 'end' for Economic Crimes Law
    // If complaint is in 'correctiveMeasures' stage and there are action plans that were created in this stage then set stage as 'implementation' if there is at least one action plan in term, otherwise set stage as 'finalized'
    // If complaint is in 'implementation' stage and all action plans created in 'correctiveMeasures' are in stage 'finalized' then set stage as 'finalized'
    // If complaint is in 'finalized' stage and all action plans created in 'correctiveMeasures' are in stage 'end' then set stage as 'end'
    payload.stage = complaintData.stage === 'registration'
      ? 'analysis'
      : complaintData.stage === 'analysis' && complaintData.actionPlansIds?.length
        ? 'approval'
        : complaintData.stage === 'approval' && complaintData.actionPlans.some(e => e.stage === 'corrective' || e.approvalNextStage === 'corrective' || e.stage === 'verification' || e.approvalNextStage === 'verification')
          ? complaintData.actionPlans.some(e => e.stage === 'verification' || e.approvalNextStage === 'verification')
            ? 'verification'
            : 'corrective'
          : complaintData.stage === 'corrective' && complaintData.actionPlans.some(e => e.stage === 'verification' || e.correctiveNextStage === 'verification')
            ? 'verification'
            : complaintData.stage === 'verification' && complaintData.actionPlans.every(e => e.verificationNextStage === 'end')
              ? complaintData.isKarinLaw
                ? 'correctiveMeasures'
                : 'end'
              : complaintData.stage === 'correctiveMeasures' && complaintData.correctiveMeasuresPlans?.length
                ? complaintData.correctiveMeasuresPlans.some(e => e.type === 'immediate')
                  ? 'finalized'
                  : 'implementation'
                : complaintData.stage === 'implementation' && complaintData.actionPlans.some(e => e.stage === 'finalized' || e.implementationNextStage === 'finalized')
                  ? 'finalized'
                  : complaintData.stage === 'finalized' && complaintData.actionPlans.every(e => e.stageCreated === 'analysis' || e.finalizedNextStage === 'end')
                    ? 'end'
                    : complaintData.stage

    axios
      .post('/graphql', {
        query: updateComplaint,
        variables: { query: { _id: route.value.params.id }, data: payload },
      })
      .then(({ data }) => {
        if (data.errors) throw new Error(data.errors[0].message)
        showSuccessMessage(i18n.t('message.complaint_updated'))

        const originalStage = complaintData.stage
        complaintData.stage = payload.stage

        // If case passed to stage 'analysis' send email to proposal responsible with the details of the case updated
        if (complaintData.proposalResponsible && originalStage !== 'analysis' && payload.stage === 'analysis') {
          sendEmailCase(complaintData, "update", 'proposalResponsible', 'complaint')
        }

        // If case passed to stage 'approval' send email to approval responsible with the details of the case updated
        else if (complaintData.approvalResponsible && originalStage !== 'approval' && payload.stage === 'approval') {
          sendEmailCase(complaintData, "update", 'approvalResponsible', 'complaint')
        }

        // If case passed to stage 'verification' send email to verification responsible with the details of the case updated
        else if (complaintData.verificationResponsible && originalStage !== 'verification' && payload.stage === 'verification') {
          sendEmailCase(complaintData, "update", 'verificationResponsible', 'complaint')
        }
      })
      .catch(error => {
        console.log(error)
        showErrorMessage(i18n.t('message.complaint_update_error'))
      })
      .finally(() => {
        router.push({ name: 'organization-complaint-list' })
      })
  }

  const addNewMessage = (message) => {
    try {
      const action = { $push: { messages: message } }
      updateItem({ collection, query, action })
    } catch (error) {
      console.log(error)
    }
  }

  const deleteActionPlans = (ids) => {
    ids.forEach(e => {
      axios
        .post('/graphql', {
          query: updateActionPlan,
          variables: { query: { _id: e }, data: { deleted: true } },
        })
        .then(({ data }) => {
          if (data.errors) throw new Error(data.errors[0].message)
        })
        .catch(error => {
          console.log(error)
        })
    })
  }

  return {
    update,
    show,
    complaintData,
    isSubmitting,
    updateActiveTab,
    addNewMessage,
  }
}
